import { render, staticRenderFns } from "./rqqy.vue?vue&type=template&id=4541c762&scoped=true&"
import script from "./rqqy.vue?vue&type=script&lang=js&"
export * from "./rqqy.vue?vue&type=script&lang=js&"
import style0 from "./rqqy.vue?vue&type=style&index=0&id=4541c762&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4541c762",
  null
  
)

export default component.exports