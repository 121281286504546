<!-- 人气企业 -->
<template>
	<!-- 分类 -->
	<div class="type">
		<div class="type-item" v-for="(item,index) in list" :key="item.id" v-if="length==true?index<3:index>-1" @click="clkEntAil(item.id)">
			<div class="tle">
				<img :src="item.thumb || defaultImgUrl" alt="">
			</div>
			<div class="type-ctx">
				<div>
					<div class="tle">
						<h3>{{item.title}}</h3>
						<h6 :style="{backgroundColor:themeColor}">{{ item.plate_name }}</h6>
					</div>
					<h5 class="h5mgt">所属行业：{{item.business}}</h5>
					<h5>{{item.description}}</h5>
				</div>
				<div class="adres">
					<i class="iconfont">&#xe613;</i>
					{{item.city_name}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			themeColor:{
				type:String,
				default:""
			},
			list:{
				type:Array,
				default:()=>[]
			},
			length:{
				// type:Number,
				type: Boolean,
				default: false
			},
			defaultImgUrl: {
				type: String,
				default: 'http://www.gsotc.com.cn//uploads/images/20220928/cffad0f8b544fa1f10ebefb36ec3af88.jpg'
			}
		},
		methods:{
			// 点击进入详情
			clkEntAil(id) {
				let type = this.$route.query.type
				this.$router.push({name:"areaDail",query:{type,id}})
			}
		}
	}
</script>

<style lang="less" scoped>
	// 分类
	.type {
		display: grid;
		grid-template-columns: repeat(3,1fr);
		column-gap: 20px;
		row-gap: 30px;
		margin-top: 30px;
		.type-item {
			display: flex;
			flex-direction: column;
			.tle {
				img {
					width: 100%;
					height: 250px;
					object-fit: cover;
				}
			}
			.type-ctx {
				background-color: #f5f6f7;
				box-sizing: border-box;
				padding: 20px;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.tle {
					display: flex;
					align-items: center;
					h3 {
						color: rgba(51, 51, 51, 1);
						font-size: 20px;
						font-weight: 400;
						line-height: 30px;
						margin-right: 8px;
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
					}
					h6 {
						color: rgba(255, 255, 255, 1);
						font-size: 14px;
						font-weight: 400;
						line-height: 21px;
						background-color: var(--zjColor);
						padding: 0 12px;
						border-radius: 5px;
						flex-shrink: 0;
					}
				}
				h5 {
					color: rgba(102, 102, 102, 1);
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
					&.h5mgt{
						margin: 20px 0;
					}
				}
				.adres {
					line-height: 24px;
					color: rgba(153, 153, 153, 1);
					font-size: 16px;
					font-weight: 400;
					margin-top: 20px;
				}
			}
			cursor: pointer;
			transition: all .5s;
			&:hover {
				box-shadow: 0 0 10rpx rgba(0,0,0,.5);
				transform: translateY(-5px);
			}
		}
	}
	
	@media (max-width:1100px) {
		.type {
			grid-template-columns: repeat(2,1fr);
		}
	}
	
	@media (max-width:800px) {
		.type {
			column-gap: 15px;
			.type-item {
				.type-ctx {
					padding: 15px;
				}
			}
		}
	}
	
	@media (max-width:768px) {
		.type {
			margin-top: 15px;
			grid-template-columns: repeat(1,1fr);
			.type-item {
				.tle {
					img {
						height: 200px;
					}
				}
				.type-ctx {
					padding:10px;
					.tle {
						h3 {
							font-size: 16px;
						}
						h6 {
							font-size: 12px;
						}
					}
					h5 {
						font-size: 14px;
						&.h5mgt {
							margin: 5px 0;
						}
					}
					.adres {
						margin-top: 5px;
						font-size: 12px;
						i {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
</style>