<template>
	<div>
		<!-- 导航条 -->
		<scNav navTag="scIndex" :themeColor="themeColor" :navs="navs"></scNav>
		<!-- 轮播图 -->
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item,index) in banners" :key="index">
					<img :src="item.thumb" alt="">
				</div>
			</div>
			<!-- 分页指示点 -->
			<ul class="pre-tips">
				<li class="pre-tip" :class="preIndex==index?'active':''" v-for="(item,index) in banners" :key="item.id">
					<p class="pre-cle"></p>
					<p class="pre-num">{{'0'+(index+1)}}</p>
				</li>
			</ul>
		</div>
		<!-- 内容专区 -->
		<div class="sc-width">
			<!-- 新增 -->
			<div class="sc-add">
				<div class="sca1">
					<div class="sca1-item">
						<p :style="{color:themeColor}">{{newAdd}}</p>
						<p>本月新增</p>
					</div>
					<div class="sca1-item">
						<p :style="{color:themeColor}">{{allAdd}}</p>
						<p>累计挂牌企业</p>
					</div>
				</div>
				<div class="sca2">
					<div class="sc2-tle">
						<h2 class="tle" :style="{color:themeColor}">企业介绍</h2>
						<h5 class="lmore" @click="clkRqqyMore">查看更多<i class="iconfont">&#xe642;</i></h5>
					</div>
					<div class="sca2-img">
						<div v-for="(item,index) in rqqys" class="img-item" @click="clkRqqy(item.id)">
							<div style="overflow: hidden;">
								<img :src="item.thumb || defaultImgUrl" alt="">
							</div>
							<p class="ctx">{{item.title}}</p>
						</div>
					</div>
				</div>
			</div>

			<!-- 人气企业 -->
			<div class="rqqy">
				<h2 class="tle" :style="{color:themeColor}">人气企业</h2>
				<rqqy :themeColor="themeColor" :list="rqqys" :length="true"></rqqy>
			</div>
		</div>

		<!-- 共享产品服务 -->
		<div class="gpdt sc-width">
			<div class="gp-tt">
				<h2 class="tle" :style="{color:themeColor}">专享产品服务</h2>
				<h5 class="lmore" @click="clkGpMore">查看更多<i class="iconfont">&#xe642;</i></h5>
			</div>
			<!-- 列表 -->
			<newList @clkCup="clkCup" :themeColor="themeColor" :policy="gps"></newList>
		</div>

		<!-- 新闻 -->
		<news :bgColor="themeColor"></news>
		
		<bottom :bgColor="themeColor"></bottom>
	</div>
</template>

<script>
	import scNav from '@/components/special/scNav'
	import rqqy from '@/components/special/rqqy'
	// 新闻
	import news from "@/components/index/news"
	import newList from "@/components/new/newList"
	export default {
		data() {
			return {
				defaultImgUrl: 'http://www.gsotc.com.cn//uploads/images/20220928/cffad0f8b544fa1f10ebefb36ec3af88.jpg',
				
				themeColor: "",
				tableData: [],
				banners: [],
				preIndex: 0,
				navs:[],
				// 企业专区
				rqqys:[],
				// 挂牌动态
				gps:[],
				// 本月新增
				newAdd:0,
				// 全部新增
				allAdd:0
			}
		},
		created() {
			let type = this.$route.query.type
			// 查询挂牌动态
			this.getGp()
			if (type == 1) {
				// 专精特新版
				this.themeColor = "#386eb8"
				// 企业介绍
				this.getComDal(118)
				// this.getNewAdd(196,1)
				// this.getNewAdd(197,2)
				
				this.getNewAdd("Specialization/getList",196,1)
				this.getNewAdd("Specialization/getList",197,2)
			} else if (type == 2) {
				// 科技创新版	
				this.themeColor = "#47a8c8"
				// 企业介绍
				this.getComDal(127)
				// this.getNewAdd(198,1)
				// this.getNewAdd(199,2)
				
				this.getNewAdd("Technology/getList",198,1)
				this.getNewAdd("Technology/getList",199,2)
			} else if (type == 3) {
				// 交易版
				this.themeColor = "#d3a21e"
				// 企业介绍
				this.getComDal(135)
				// this.getNewAdd(200,1)
				// this.getNewAdd(201,2)
				
				this.getNewAdd("Trading/getList",200,1)
				this.getNewAdd("Trading/getList",201,2)
			}
			
		},
		components: {
			scNav,
			rqqy,
			news,
			newList
		},
		mounted() {
			this.getBanner()
			this.topNav()
		},
		methods: {
			// 轮播图
			getSwiper() {
				let that = this
				new Swiper('.swiper-container', {
					pagination: '.swiper-pagination', //分页器
					loop: true, //循环
					paginationClickable: true, //分页器点击
					autoplay: 5000, //时长
					speed: 600,
					grabCursor: true,
					onSlideChangeEnd: function(swiper) {
						that.preIndex = swiper.realIndex
					}

				})
			},
			// 获取首页轮播
			getBanner() {
				let url = this.$api.index.getBanner
				let type = this.$route.query.type
				let catid = 0
				if (type == 1) {
					catid = 124
				} else if (type == 2) {
					catid = 141
				} else if (type == 3) {
					catid = 142
				}
				this.$axios(url, {
					params: {
						catid
					}
				}).then(res => {
					if (res.code) {
						this.banners = res.list
						this.$nextTick(() => {
							this.getSwiper()
						})
					}
				})
			},
			//获取顶部导航
			async topNav(catid) {
				let res = await this.$axios.get("Specialization/topNav", {
					params: {
						catid,
					}
				})
				if (res.code) {
					this.navs = res.list
				}
			},
			// 获取企业介绍
			getComDal(catid) {
				this.$axios.get("Specialization/getEnterpriseList",{
					params:{
						catid,
						limit:10,
						page:1,
						plate:0,
						industry:0,
						capital:0,
						city:0
					}
				}).then(res=>{
					if(res.code) {
						this.rqqys = res.data.data
					}
				})
			},
			// 点击企业介绍详情
			clkRqqy(id) {
				let type = this.$route.query.type
				this.$router.push({name:"areaDail",query:{type,id}})
			},
			// 企业查看更多
			clkRqqyMore() {
				let type = this.$route.query.type
				this.$router.push({name:"area",query:{type}})
			},
			// 查询挂牌动态
			getGp() {
				let type = this.$route.query.type
				let catid = 0
				let url = ""
				if(type==1) {
					catid = 119
					url = "Specialization/getList"
				}else if(type==2) {
					catid = 128
					url = "Technology/getList"
				}else if(type==3) {
					catid = 136
					url = "Trading/getList"
				}
				this.$axios.get(url,{
					params:{
						catid,
						limit:5,
						page:1
					}
				}).then(res=>{
					if(res.code) {
						this.gps = res.data.data
					}
				})
			},
			// 共享产品服务查看更多
			clkGpMore() {
				let type = this.$route.query.type
				this.$router.push({name:"scGpDail",query:{type}})
			},
			// 共享产品服务查看详情
			clkCup(index,id) {
				let type = this.$route.query.type
				this.$router.push({name:"scGpDails",query:{type,id}})
			},
			// 查询新增数据
			// getNewAdd(catid,type) {
			// 	this.$axios.get("Specialization/getList", {
			// 		params:{
			// 			path:1,
			// 			limit:1,
			// 			catid
			// 		}
			// 	}).then(res=>{
			// 		if(res.code) {
			// 			if(type==1) {
			// 				this.newAdd = res.data.data[0].title
			// 			}else {
			// 				this.allAdd = res.data.data[0].title
			// 			}
			// 		}
			// 	})
			// }
			
			// 改
			getNewAdd(api,catid,type) {
				this.$axios.get(api, {
					params:{
						path:1,
						limit:1,
						catid
					}
				}).then(res=>{
					if(res.code) {
						if(type==1) {
							this.newAdd = res.data.data[0].title
						}else {
							this.allAdd = res.data.data[0].title
						}
					}
				})
			}
			
		}
	}
</script>

<style lang="less" scoped>
	.pre-tips {
		display: flex;
		justify-content: center;
		position: absolute;
		z-index: 9999999999999;
		left: 50%;
		bottom: 40px;
		transform: translateX(-50%);

		.pre-tip {
			display: flex;
			flex-direction: column;
			align-items: center;
			opacity: .5;
			margin-right: 40px;

			&:last-child {
				margin-right: 0;
			}

			.pre-cle {
				width: 5px;
				height: 5px;
				border-radius: 50%;
				border: 1px solid #fff;
				margin-bottom: 5px;
			}

			.pre-num {
				color: #fff;
				font-size: 18px;
			}

			&.active {
				opacity: 1;

				.pre-cle {
					background-color: #fff;
				}
			}
		}
	}

	.tle {
		line-height: 36px;
		color: var(--zjColor);
		font-size: 24px;
		font-weight: 700;
	}

	.lmore {
		line-height: 24px;
		color: rgba(153, 153, 153, 1);
		font-size: 16px;
		font-weight: 400;
		cursor: pointer;
		i {
			font-size: 12px;
		}
	}

	.swiper-container {
		img {
			width: 100%;
		}
	}

	.gstip {
		max-width: 1440px;
		margin: 0 auto;
		padding: 20px 0;
		line-height: 24px;
		color: rgba(51, 51, 51, 1);
		font-size: 16px;
		font-weight: 400;
	}

	.sc-add {
		display: flex;
		align-items: center;

		.sca1 {
			width: 553px;
			flex-shrink: 0;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			background-color: #eff4fa;

			.sca1-item {
				height: 216px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				p:first-child {
					color: var(--zjColor);
					line-height: 47px;
					color: rgba(56, 111, 182, 1);
					font-size: 32px;
					font-weight: 700;
				}

				p:last-child {
					line-height: 33px;
					color: rgba(51, 51, 51, 1);
					font-size: 22px;
					font-weight: 400;
				}
			}
		}

		.sca2 {
			flex-grow: 1;
			overflow: auto;
			margin-left: 40px;

			.sc2-tle {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 20px;
			}

			.sca2-img {
				display: flex;
				overflow: auto;

				.img-item {
					margin-right: 20px;
					cursor: pointer;
					img {
						transition: transform .5s;
					}
					&:hover {
						img {
							transform: scale(1.1);
						}
					}
					&:last-child {
						margin-right: 0;
					}

					img {
						width: 200px;
						height: 112px;
						background: #fff;
						object-fit: cover;
					}

					.ctx {
						line-height: 24px;
						color: rgba(51, 51, 51, 1);
						font-size: 16px;
						font-weight: 400;
						text-align: center;
						margin-top: 10px;
					}
				}
			}
		}
	}

	.rqqy {
		margin-top: 80px;
		background-color: white;
	}

	.gpdt {
		padding-top: 80px;
		max-width: 1440px;
		margin: 0 auto;
		box-sizing: border-box;
		padding: 40px;
		background-color: #fff;

		.gp-tt {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
		}
	}
	/deep/ .cup-item {
		padding: 20px 0;
	}

	@media (max-width:1480px) {
		.gstip {
			padding: 20px;
		}

		.sc-width {
			padding: 20px;
		}
	}

	@media (max-width:1300px) {
		.sc-add {
			.sca1 {
				width: 45%
			}
		}
	}

	@media (max-width:900px) {
		.sc-add {
			flex-direction: column;

			.sca1 {
				width: 100%;

				.sca1-item {
					height: 100px;

					P:first-child {
						font-size: 28px
					}

					p:last-child {
						font-size: 18px;
					}
				}
			}

			.sca2 {
				width: 100%;
				margin-left: 0;
				margin-top: 30px;
			}
		}

		.tle {
			line-height: 1;
			font-size: 20px;
		}

		.lmore {
			line-height: 24px;
			color: rgba(153, 153, 153, 1);
			font-size: 14px;
			font-weight: 400;
		}
	}
	
	@media (max-width:768px) {
		.sc-width {
			padding: 10px;
			margin-bottom: 30px;
		}
		.pre-tips {
			bottom:5px;
			.pre-tip {
				margin-right:20px;
				.pre-num {
					font-size: 14px;
				}
			}
		}
		.sc-add {
			.sca1 {
				.sca1-item {
					P:first-child {
						font-size: 22px;
					}
					P:last-child {
						font-size: 16px;
					}
				}
			}
			.sca2 {
				.sc2-tle {
					margin-bottom: 15px;
				}
				.sca2-img {
					.img-item {
						margin-right:10px;
						.ctx {
							font-size: 14px;
							margin-top: 5px;
							line-height: 1.2;
						}
					}
				}
			}
		}
		.rqqy {
			margin-top: 30px;
		}
		.gpdt {
			.gp-tt {
				margin-bottom: 0;
			}
		}
		/deep/ .type {
			margin-top: 15px;
			.type-item {
				.tle {
					img {
						height: 200px;
					}
				}
				.type-ctx {
					padding:10px;
					.tle {
						h3 {
							font-size: 16px;
						}
						h6 {
							font-size: 12px;
						}
					}
					h5 {
						font-size: 14px;
						&.h5mgt {
							margin: 5px 0;
						}
					}
					.adres {
						margin-top: 5px;
						font-size: 12px;
						i {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
</style>
